* {
  transition: all 0.3s ease;
}

.bg-dark {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-transp {
  background-color: rgba(0, 0, 0, 0);
}

.title {
  margin-top: 2rem;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  position: relative;
}

.lang-icon {
  text-align: center;
  width: 40px;
  margin: 10px;
  padding: 0px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.5);
}

.dropdown-menu {
  min-width: 0;
  padding: 0;
}
.dropdown-item {
  padding: 0.25rem;
}

.title::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50px;
  height: 5px;
  background-color: #0078ff;
}

.box-shadow {
  padding: 1.5rem 1.25rem;
  margin: 3rem 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.height-80 {
  height: 80%;
}

.circle {
  position: relative;
  height: 100px;
  width: 100px;
  font-size: 2rem;
  border-radius: 50%;
  margin: 0 auto 2rem auto;
  text-align: center;
  box-shadow: 0 0 0 10px #0078ff;
  display: block;
}

.circle:hover {
  background-color: #0078ff;
  box-shadow: 0 0 0 3px #cde1f8;
}

.circle:hover * {
  color: #fff;
}

.center-vertical,
.circle svg {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  transform: translateY(-50%);
}

.zoom-frame {
  overflow: hidden;
}
.zoom-frame img {
  transform: scale(1);
}
.zoom-frame:hover img {
  transform: scale(1.1);
}
